<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <div class="vx-row mb-base">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="text-sm font-semibold">TTD Customer</label>
          <p v-if="!booking.file_customer_signature_url" class="mt-3 opacity-75 text-sm">Belum tersedia.</p>
          <img v-else :src="booking.file_customer_signature_url" @click="showCustomerSignature" alt="signature" class="w-64 h-auto mt-3 bg-white border d-theme-border-grey-light border-solid"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="text-sm font-semibold">Bukti Transfer</label>
          <p v-if="!booking.file_bukti_transfer_url" class="mt-3 opacity-75 text-sm">Belum tersedia.</p>
          <img v-else :src="booking.file_bukti_transfer_url" @click="showBuktiTransfer" alt="signature" class="w-64 h-auto mt-3 bg-white border d-theme-border-grey-light border-solid"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="text-sm font-semibold">SKUP Approval</label>
          <ApprovalProgress
            class="mt-3"
            ref="approvalProgress"
            type="SKUP-CUSTOMER-KONTRAK"
            :id-ref="$route.params.idBooking"/>
        </div>
      </div>
    </div>

    <!--modals-->
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import ImageViewer from '@/views/components/image-viewer/ImageViewer'

export default {
  name: 'TabApprovals',
  props: ['isActive'],
  components: {
    ImageViewer,
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  computed: {
    storeBookingDetail () {
      return this.$store.state.marketing.bookingDetail
    },
    booking () {
      return this.storeBookingDetail.booking
    }
  },
  data () {
    return {
      isLoading: false,
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      }
    }
  },

  methods: {
    showBuktiTransfer () {
      const image = this.booking.file_bukti_transfer_url
      this.showImages([image])
    },

    showCustomerSignature () {
      const image = this.booking.file_customer_signature_url
      this.showImages([image])
    },

    showImages (images) {
      this.$refs.viewer.show(images)
    }
  }
}
</script>
